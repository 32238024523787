import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Bot } from '../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../assets/icons/top-wave.svg';
import { ScContainer } from '../components/container/styled';
import { ScPage } from '../components/page/styled';
import { ScTop } from '../components/Top/styled';
import { MainLayout } from '../layouts/main-layout';
import { FaqSections } from '../sections/EmployeeFaq/FaqSections';
import { EmployerFaqInfoSection } from '../sections/EmployerFaq/InfoSection';

const accordionsBike = [
	{
		title: 'What is the Gogeta Bike scheme?',
		columns: 2,
		text: [
			`<p>Gogeta Bike is a cycle to work scheme. Cycle to work was introduced by the UK Government to get more people cycling. It lets employees pay for bikes and cycling accessories out of their gross salary, before tax and national insurance contributions, saving them money.</p>`,
			`<p>And because they pay for the cost of their bike by salary sacrificing the cost out of their monthly salary, it also lets them spread the cost over a year.</p>`,
		],
	},
	{
		title: 'How much can employees save?',
		columns: 2,
		text: [
			`<p>Cycle to work savings are typically between 24%-44% on standard retail prices, depending on your tax band. However, because Gogeta Bike has the lowest commission for retailers, they never pass on surcharges to you when using our scheme and you’ll get access to other deals (like sale bikes).</p>`,
			`<p>This means the savings you make are usually even greater, sometimes over 50%. To see your personalised savings, based on your salary and your bike cost, please check out <a href="/employee-cycling/#savings-calculator" target="_blank">our savings calculator</a>.</p>`,
		],
	},
	{
		title: 'Can any sized business sign up to Gogeta Bike?',
		columns: 1,
		text: [
			`<p>Yes, companies with 1 or 1000 (or more!) employees can sign up to Gogeta Bike. We have plans for every sized team. All our plans share the same best in class features and savings. The key difference is the onboarding and application approvals have been optimised for company size. Only PAYE employees can use Gogeta Bike, as the savings are made via salary sacrifice.</p>`,
		],
	},
	{
		title: 'I’m self-employed, can I use Gogeta Bike?',
		columns: 1,
		text: [
			`<p>As long as you are PAYE (i.e. you pay yourself a salary through your own business) you can sign up to Gogeta Bike and apply as an employee. By using the Gogeta scheme, not only will you be saving the personal tax on your salary sacrifice, but your company will save on the Employer’s NI.</p>`,
		],
	},
	{
		title: 'What is the Gogeta Bike platform fee?',
		columns: 2,
		text: [
			`<p>Gogeta Bike charges employees a platform fee to run and manage the scheme. The gross fee is 6% on the total voucher amount. However as this fee is added to the salary sacrifice amount, employees save the tax on the fee as well, so the net effective fee cost is 3.2% -4.3% (depending on their tax band). Some employers allow employees to transfer this fee to them, as it is covered by the national insurance savings they make. You can decide this when you apply.</p>`,
			`<p>Remember - because Gogeta Bike has the lowest commission (and is the only cycle to work scheme endorsed by the Association of Cycle Traders) bike retailers pass on the best deals and savings to your employees, and never charge a surcharge for accepting Gogeta Bike vouchers. So we can confidently say that Gogeta Bike is the most generous cycle to work scheme for employees.</p>`,
		],
	},
	{
		title: 'Can employees use any retailer?',
		columns: 1,
		text: [
			`<p>Employees can use Gogeta Bike at any participating retailer (check our retailer list here). If their preferred retailer isn’t signed up, they can get in touch and we’ll get them on board straight away. We are the only cycle to work scheme endorsed by the Association of Cycle Traders, due to our low commissions and fair and transparent pricing, and we are always growing our retail base.</p>`,
		],
	},
	{
		title: 'What can employees buy with their Gogeta Bike voucher?',
		columns: 1,
		text: [
			`<p>Employees can get any adult bike, including electric bikes, plus a wide range of cycling equipment and clothing. They can select cycling equipment with a bike, or if they already have a bike and are cycling to work they can choose to get equipment only, with all the same tax savings.</p>`,
		],
	},
	{
		title: 'Is there a limit to how much employees can apply for a voucher for?',
		columns: 2,
		text: [
			`<p>The Gogeta Bike voucher limit is completely up to you. We have a default limit of £5000, which enables employees to purchase e-bikes if they wish (an important choice for people with longer commutes).</p>`,
			`<p>You can adjust this limit up or down in your employer portal after you sign up. Employees cannot salary sacrifice an amount that takes them below the National Minimum Wage.</p>`,
		],
	},
	{
		title: 'What happens at the end of the salary sacrifice term?',
		columns: 2,
		text: [
			`<p>The bike is now your employee’s, for all intents and purposes. However, there is a quirk in the HMRC cycle to work guidance which states that if they were to take legal ownership of their bike and equipment immediately upon paying off the salary sacrifice amount, they would face a benefit in kind tax of 25% based on the value of the bike (see HMRC’s depreciation table which is in this article below).</p><p>Therefore, in order to ensure employees don’t have to pay benefit in kind tax and can take advantage of the full cycle to work savings, Gogeta Bike (as well as every other cycle to work scheme), enters them into an extended hire agreement whereby they hire the bike for the remaining period and at the end of that the value is negligible.</p>`,
			`<p>To transfer ownership we have to charge a token amount, we make this as low as possible and charge £1. We know it sounds a bit complicated but in reality it isn’t. At the end of the initial salary sacrifice term, the bike is your employee’s bike in every real sense. It’s also worth remembering - some other cycle to work schemes charge as much as 10% as a transfer of ownership fee. As we have explained, we have to charge something, but this will never be more than £1.</p>`,
		],
	},
	{
		title:
			'What happens if the employee leaves their job during the initial salary sacrifice period?',
		columns: 1,
		text: [
			`<p>If an employee leaves during the salary sacrifice period, under the terms of the salary sacrifice agreement which they will have signed, you can deduct the outstanding balance from their final net pay.</p>`,
		],
	},
	{
		title: 'What happens if they take maternity/paternity/adoption leave?',
		columns: 1,
		text: [
			`<p>The salary sacrifice can continue as long as the employee’s salary remains at an acceptable level to ensure affordability, and it does not drop below the National Minimum Wage.</p>`,
		],
	},
	{
		title: 'What is the Gogeta Bike flexi-voucher?',
		columns: 2,
		text: [
			`<p>Unlike other cycle to work schemes, Gogeta Bike’s voucher is completely flexible, so employees can spend the balance over different transactions with different retailers.</p>`,
			`<p>Employees can check the balance of their Gogeta Bike flexi-voucher in their portal (they’ll be sent a link to this when their application is approved).</p>`,
		],
	},
	{
		title:
			'If employees don’t spend the full amount on their Gogeta Bike voucher after the initial salary sacrifice period, can they get a refund on the balance?',
		columns: 1,
		text: [
			`<p>No. As employees have entered into a salary sacrifice agreement with you, they are committed to the amount they selected when they applied. Any amount they do not spend will still be repaid via their salary sacrifice, so it's important they select the correct amount and then fully spend it.</p>`,
		],
	},
	{
		title: 'What happens if an employee’s bike is stolen?',
		columns: 1,
		text: [
			`<p>It is your employees responsibility to insure their bike, should they wish to. If their bike is stolen during the salary sacrifice period the employee remains liable for any remaining payments. If the bike is insured and replaced then the agreement continues as normal.</p>`,
		],
	},
];

const accordionsNursery = [
	{
		title: 'Who is Gogeta?',
		columns: 2,
		text: [
			`<p>Gogeta is a salary sacrifice benefits provider. Our mission is to help people’s salaries go further by paying for things like nursery fees or a new bike out of their gross salary.</p>`,
			`<p>Our salary sacrifice benefits are simple to use and are completely cost neutral to you as an employer, allowing you to make a tangible difference to people’s take home pay without increasing salaries.</p>`,
		],
	},
	{
		title: 'What is Gogeta Nursery?',
		columns: 2,
		text: [
			`<p>Gogeta Nursery is a workplace nursery scheme that lets employees pay for nursery bills out of their gross salary, before tax and national insurance contributions. This allows them to save between 19% and 40% (exact savings depend on their tax code).</p>`,
			`<p>Unlike the Government’s Tax Free Childcare scheme, which caps savings at £2000 per year, with Gogeta Nursery there is no upper limit on savings.</p>`,
		],
	},
	{
		title:
			'Can employees use Gogeta Nursery at the same time as benefiting from the Government ‘free’ funded hours scheme?',
		columns: 1,
		text: [
			`<p>Yes. Any discount on nursery fees is automatically deducted by the nursery and parents can use Gogeta Nursery (and benefit from the savings) on the remaining amount due after the funded hours have been deducted.</p>`,
		],
	},
	{
		title: 'How does this compare to Tax Free Childcare?',
		columns: 2,
		text: [
			`<p>The Government’s Tax Free Childcare scheme saves parents 20% - but the saving is capped at £500 every three months (£2000 a year). Gogeta Nursery saves parents between 19-40% but there are no limitations on how much people can save. So, in most circumstances a parent saves much more with Gogeta Nursery over a year.</p>`,
			`<p>If an employee wanted to use Gogeta Nursery to pay for one child’s nursery fees and use Tax Free Childcare for an older child's after-school club (for example) that is completely fine.</p>`,
		],
	},
	{
		title: 'Can employees use the Gogeta Nursery scheme with their current nursery?',
		columns: 1,
		text: [
			`<p>Almost all nurseries accept Gogeta Nursery. In fact they love it because of the additional funding they receive from the employers of every parent using the scheme. Once parents apply, we’ll get in touch with their child’s nursery and get them signed up if they aren’t already.</p>`,
		],
	},
	{
		title: 'Can employees use the Gogeta Nursery scheme with their current nursery?',
		columns: 1,
		text: [
			`<p>No, employees can only use Gogeta Nursery to pay nursery fees at OFSTED registered nurseries.</p>`,
		],
	},
	{
		title: 'How do you handle HMRC compliance?',
		columns: 2,
		text: [
			`<p>Gogeta’s compliance measures are of the highest standard. Our industry leading active management protocols ensure employers form truly productive partnerships with nurseries, via their employee who acts as the company’s representative (in line with HMRC guidance). The employer representative has genuine influence over the nursery's management and care provision decisions.</p><p>Gogeta also facilitates ongoing review and governance of the nursery by the employer representative, outside of and beyond the bi-annual compliance meetings.</p>`,
			`<p>We are the only workplace nursery provider that has a dedicated compliance portal for employers, which stores all documentation regarding compliance reporting. This includes all minutes and actions arising from employee/nursery meetings, nursery progress against spend you are funding and any evidence of the contribution you are making to the nurseries. This rigorous approach to compliance is unique to Gogeta.</p>`,
		],
	},
	{
		title: 'What about the financial contribution to nurseries?',
		columns: 2,
		text: [
			`<p>As part of the scheme requirements, employers must make a variable financial contribution of 12.5% of the parent’s fees to the nursery. This financial contribution is covered by the national insurance savings you make make, so this benefit remains cost neutral to you.</p>`,
			`<p>In this way, the financial contribution meets HMRC’s requirement of the workplace nursery scheme, that employers must meet a proportion of the overall costs of providing the care. We invoice you for this amount and then pay it directly to the nursery every month.</p>`,
		],
	},
	{
		title: 'Why do employees need to sign a 12 month contract?',
		columns: 2,
		text: [
			`<p>When an employee parent signs up to Gogeta Nursery, you as the employer are entering into a partnership with the employee’s nursery, and agree to pay the additional 12.5% of parent’s nursery fees per month for 12 months. For this reason, Gogeta Nursery salary sacrifice agreements run for 12 months.</p>`,
			`<p>It is also standard practice, as per HMRC guidelines for salary sacrifice to be effective, to set terms for at least 12 months.</p>`,
		],
	},
	{
		title: 'What happens if an employee’s nursery fees vary each month?',
		columns: 2,
		text: [
			`<p>That’s fine, we know many parent’s childcare needs change month by month and so their invoice and salary sacrifice amount will need to be adjusted accordingly. But we make this as simple as possible for you, the employer, to manage. When an employee makes their initial application, they’ll choose the salary sacrifice amount based on their next month’s nursery fees.</p>`,
			`<p>But we can adjust their salary sacrifice amount every month if required, with just 24 hours notice, based on their latest invoice. We’ll let you know of any resulting changes to payroll and work with your payroll deadlines to ensure you are notified before payroll cut off dates.</p>`,
		],
	},
	{
		title: 'What is the Gogeta Nursery platform fee?',
		columns: 2,
		text: [
			`<p>Parents pay a platform fee to use the scheme, which is added to their salary sacrifice amount. This is so we can cover the costs to manage compliance requirements of the scheme.</p>`,
			`<p>The platform fee works out as a net cost to parents of between 7.25-9% (depending on their tax band). All of the savings displayed in our calculators are the true savings to employees (inclusive of the platform fee).</p>`,
		],
	},
	{
		title: 'Is there a limit to how much employees can salary sacrifice?',
		columns: 1,
		text: [
			`<p>There is no limit but an employee's salary must not fall below the National Minimum Wage after salary sacrifice deductions have been made.</p>`,
		],
	},
	{
		title: 'Can an employee apply for the scheme while on maternity leave?',
		columns: 2,
		text: [
			`<p>An employee’s salary sacrifice cannot start until they have nursery fees to pay, so unless their child is already at nursery we recommend waiting until their return to work to apply. However, we recommend they let you and us know that they wish to join on their return to work, and we can ensure that their chosen nursery is signed up and ready to go as soon as they are.</p>`,
			`<p>If they are on maternity leave with a baby and have an older child at nursery then they can apply for Gogeta Nursery. However, they cannot salary sacrifice if the amount would take them below National Wage.</p>`,
		],
	},
	{
		title:
			'What happens if an employee goes on maternity or paternity leave during the scheme contractual period?',
		columns: 1,
		text: [
			`<p>If an employee needs to pause their salary sacrifice agreement whilst on maternity or paternity leave they can but the minimum £30 per month payments to the nursery will need to continue until the end of the 12 month period.</p>`,
		],
	},
	{
		title: 'An employee has more than one child in nursery, can they use the scheme for them both?',
		columns: 2,
		text: [
			`<p>Yes. If both children are in the same nursery at the point of application, they will both be added to one initial contract. If one child is added later, there will be a separate agreements.</p>`,
			`<p>The employer will make an additional contribution of 12.5% of the parents total nursery costs to the nursery, as normal. As with a single application, you will need to ensure the total does not take employees below National Minimum Wage.</p>`,
		],
	},
];

const EmployeeFaq = () => {
	return (
		<MainLayout
			title="Employer FAQ | Gogeta Salary Sacrifice Scheme for Your Business"
			description="Get answers to your questions about Gogeta’s salary sacrifice schemes. Learn how to easily implement tax-saving benefits for your employees with minimal admin."
		>
			<ScPage>
				<ScTopWrapper>
					<ScTop disableHeight>
						<Top />
						<ScContainerWrapper>
							<ScContainer>
								<FaqSections
									title={'Employer FAQs'}
									accordionBikes={accordionsBike}
									nurseriesNurseries={accordionsNursery}
								/>
							</ScContainer>
						</ScContainerWrapper>
						<Bot className={'bot-img'} />
						<EmployerFaqInfoSection />
					</ScTop>
				</ScTopWrapper>
			</ScPage>
		</MainLayout>
	);
};

export const ScTopWrapper = styled.div`
	.info-section-wrapper {
		@media (min-width: 1220px) {
			margin: 80px auto 100px auto;
		}
	}
	svg:first-child {
		background: none;
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}
	.bot-img {
		background: none;
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}
`;

export const ScContainerWrapper = styled.div`
	background: ${({ theme }) => theme?.colors.white};
	padding-bottom: 60px;
`;

export default EmployeeFaq;
